import { useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik, FormikHelpers } from 'formik';
import { IoIosMail } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';

import { ForgotPasswordValidation } from 'schemas/auth';
import { ForgotPasswordType } from 'types/auth';
import { ResponseError } from 'types/common';
import { useForgotPassword } from 'hooks/authHooks';

import Logo from 'resources/images/Group_34.png';
import { svgIcons } from 'resources/icons';

import '../Login.less';

const ForgotPassword = () => {
  const [invalidLoginState, setInavlidLoginState] = useState(false);
  const history = useHistory();
  const forgotPassword = useForgotPassword();

  const formInitialValues = {
    email: '',
  };

  const handleSumbit = (values: ForgotPasswordType, formikHelpers: FormikHelpers<ForgotPasswordType>) => {
    formikHelpers.validateForm();
    formikHelpers.setSubmitting(true);

    const body = {
      ...values,
    };

    forgotPassword
      .mutateAsync(body)
      .then(response => {
        if (response?.success === true) {
          localStorage.setItem('forgotPasswordEmail', body.email);
          history.push(`/auth/resetPassword`);
        }
      })
      .catch((error: ResponseError) => {
        setInavlidLoginState(true);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };
  useEffect(() => {
    //  localStorage.setItem('forgotPassword', 'true');
    sessionStorage.setItem('forgotPassword', 'true');
    localStorage.removeItem('resetPassword');
  }, []);
  return (
    <div className="login-form-wrapper">
      <Row className="form-container">
        <Col offset={12} span={12} className="blur_bg">
          <div className="LoginForm">
            <Row>
              <Col span={24}>
                <Space
                  direction="horizontal"
                  style={{ width: '100%', justifyContent: 'center' }}
                  className="comapny_logo">
                  <img className="img-fluid logo-img pb-2" src={Logo} alt="logo" />
                </Space>
              </Col>
            </Row>
            {invalidLoginState ? (
              <div className="wrapperInavlidLogin" style={{ paddingRight: '46%' }}>
                <Space
                  direction="horizontal"
                  style={{ width: '100%', justifyContent: 'center' }}
                  className="invalidLoginText">
                  <svgIcons.errorIcon width={16} height={16} className="errorIcon" style={{ marginRight: '10px' }} />
                  <p>Email not found.</p>
                </Space>
              </div>
            ) : (
              <>
                <Row>
                  <Col span={24}>
                    <Space
                      direction="horizontal"
                      style={{ width: '100%', justifyContent: 'center' }}
                      className="formHeading">
                      <p>FORGOT PASSWORD</p>
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Space
                      direction="horizontal"
                      style={{ width: '100%', justifyContent: 'center' }}
                      className="forgotPasswordInfoText">
                      <p>Enter your email address below, and we will email instructions for setting a new one.</p>
                    </Space>
                  </Col>
                </Row>
              </>
            )}
            <Formik
              initialValues={formInitialValues}
              validationSchema={ForgotPasswordValidation}
              onSubmit={handleSumbit}>
              {formProps => (
                <Form layout="vertical" className="loginForm">
                  <Row>
                    <Col span={24}>
                      <FormItem label="Email" name="email" className="form-label" required>
                        <Input
                          prefix={<IoIosMail size={20} className="login-input-icons" />}
                          type="email"
                          name="email"
                          placeholder="name@example.com"
                          className="form-control"
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <SubmitButton type="primary" htmlType="submit" block className="login-btn forgotButton">
                        SEND REQUEST
                      </SubmitButton>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            <Row>
              <Col span={24}>
                <div className="loginReturn">
                  <p>
                    <Link to="/auth/login">
                      <svgIcons.ReturnArrowIcon width={14} height={12} /> <span>Return to login</span>
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="terms_policy">
            <p className="termPolicy-text">Term of Use. Privacy Policy</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ForgotPassword;

import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AUTH_API_BASE_URL, FORBIDDEN_ERROR } from 'constants/config';
import localStorageService from 'helpers/localStorageService';
import httpRequest from './HttpRequest';

export class APIError extends Error {
  public status: number;

  constructor(message: string, status: number) {
    super(message);

    this.status = status;
  }
}

const axios: AxiosInstance = Axios.create({
  timeout: Number(process.env.REACT_APP_API_REQUEST_TIMEOUT) || 50000,
});

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalConfig = error.config;
    if (!error.response) {
      return Promise.reject(new APIError('Unable to reach server', 0));
    }
    // if (error?.response?.data?.statusCode === 500) {
    //   window.location.href = '/Error500';
    // }
    if (error.response.data.error) {
      if (error?.response?.data?.error?.statusCode === 403) {
        return Promise.reject(new APIError(error.response.data.error, error.response.status));
      }
      if (error?.response?.data?.error?.statusCode === 401) {
        try {
          let refreshToken = null;
          let getUserInfo = null;
          let loginUserData = null;
          try {
            refreshToken = localStorageService.get('refreshToken');
            getUserInfo = localStorage.getItem('userInfo');
            loginUserData = JSON.parse(getUserInfo || '');
          } catch (err) {
            throw new Error('Not Found');
          }
          const response = await httpRequest.request({
            baseURL: AUTH_API_BASE_URL,
            url: `/Auth/access_token?grant_type=refresh_token&refresh_token=${refreshToken}&tenantId=${loginUserData?.tenantId}`,
            method: 'GET',
          });
          if (response?.data?.accessToken) {
            localStorageService.set('jwt', response?.data?.accessToken);
            localStorageService.set('refreshToken', response?.data?.refreshToken);
          }
          // originalConfig.headers.Athorization = `Bearer ${response?.accessToken}`;
          // originalConfig.headers['Authorization'] = `Bearer ${response?.accessToken}`;
          originalConfig.headers = { Authorization: `Bearer ${response?.data?.accessToken}` };
          return await new Promise((resolve, reject) => {
            axios
              .request(originalConfig)
              .then(res => {
                resolve(res);
              })
              .catch(err => {
                reject(err);
              });
          });
        } catch (_error) {
          if (_error?.statusCode === 401 || _error?.statusCode === 403 || _error.message === 'Not Found') {
            localStorage.clear();
            window.location.href = '/auth/login';
          }
          return Promise.reject(new APIError(`Request failed with ${_error.response.status}`, _error.response.status));
        }
      }

      return Promise.reject(error.response.data.error);
    }

    return Promise.reject(new APIError(`Request failed with ${error.response.status}`, error.response.status));
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function axiosRequest(options: AxiosRequestConfig): Promise<any> {
  const response = await axios(options);
  if (response.headers['x-access-token']) {
    localStorageService.set('jwt', response.headers['x-access-token']);
  }
  return response.data;
}

export default axiosRequest;

import { notification } from 'antd';
import jwt_decode from 'jwt-decode';

import { UserDetail, UserType } from 'types/user';
import localStorageService from 'helpers/localStorageService';
import moment, { Moment } from 'moment-timezone';

// export const filterSearchString = (recordValue: string | number | boolean, searchString: string): boolean =>
//   searchString?.toString().toLowerCase().includes(String(recordValue).toLowerCase());

export function isEmptyOrNull(value?: string) {
  if (!value) return true;

  return /(null|undefined|^$)/.test(value);
}

export const decodeUser = (jwt: string) => {
  const { userInfo }: { userInfo: UserType } = jwt_decode(jwt);
  return userInfo;
};

export const getTenantId = () => {
  const userInfo: UserDetail = localStorageService.get('userInfo');
  return userInfo?.tenantId || '';
};

const userInfo: UserDetail = localStorageService.get('userInfo');

export function passwordGenerator(len: number) {
  const length = len || 10;
  const string = 'abcdefghijklmnopqrstuvwxyz'; // to upper
  const numeric = '0123456789';
  const punctuation = '@$!%*?&';
  let password = '';
  let character = '';
  while (password.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random());
    const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    const entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
    let hold = string.charAt(entity1);
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    password = character;
  }
  password = password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');
  return password.substr(0, len);
}

export const generateUniqueKey = (pre: string) => `${pre}-${new Date().getTime()}`;

export const numberFormat = (val: number) => {
  const formattedNumber = new Intl.NumberFormat('en-US').format(val);
  return formattedNumber;
};

export const currencyFormat = (val: number, currency = '$') => {
  if (!val) return 0;
  return `${currency} ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const ErrorNotifications = (message: string | string[]) => {
  if (typeof message === 'object') {
    message.forEach(msg => {
      notification.error({ message: msg });
    });
  } else {
    notification.error({ message });
  }
};

export const timestampToHHMM = (milliseconds: number) => new Date(milliseconds).toISOString().slice(11, 16);
// export const timestampToHHMM = (milliseconds: number) => format(parse('18:54:00', 'HH:mm:ss', new Date()), 'hh:mm');

export const convertUnixCompanyTimeZone = (unixValue: number) => {
  const dateFromUnix = moment.unix(unixValue).tz(userInfo?.companyTimeZone || 'Asia/Karachi');
  // dateFromUnix.minutes(new Date().getTimezoneOffset())
  return dateFromUnix;
};

export const convertDateCompanyTimeZone = (date: Date | Moment) =>
  moment(date).tz(userInfo?.companyTimeZone || 'Asia/Karachi');

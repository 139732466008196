import { ReactComponent as AssetType } from 'resources/icons/format_list_numb.svg';
import { ReactComponent as UserRole } from 'resources/icons/person-bounding-box.svg';
import { ReactComponent as Length } from 'resources/icons/straighten.svg';
import { ReactComponent as Axle } from 'resources/icons/axleicon.svg';
import { ReactComponent as GWR } from 'resources/icons/Group1540.svg';
import { ReactComponent as Location } from 'resources/icons/geo-alt-fill.svg';
import { ReactComponent as Calendar } from 'resources/icons/calendar-days-solid.svg';
import { ReactComponent as IC_Calendar } from 'resources/icons/ic-calendar.svg';
import { ReactComponent as GasPump } from 'resources/icons/gas-pump-solid.svg';
import { ReactComponent as ReturnArrow } from 'resources/icons/arrow-return-left.svg';
import { ReactComponent as eyeFill } from 'resources/icons/eye-fill.svg';
import { ReactComponent as error } from 'resources/icons/exclamation-triangle-fill.svg';
import { ReactComponent as Edit } from 'resources/icons/Group 524.svg';
import { ReactComponent as Delete } from 'resources/icons/deactive.svg';
import { ReactComponent as ELD } from 'resources/icons/Group 79.svg';
import { ReactComponent as Search } from 'resources/icons/Search 38.svg';
import { ReactComponent as SerialNumber } from 'resources/icons/Group 2299.svg';
import { ReactComponent as Building } from 'resources/icons/Group 2116.svg';
import { ReactComponent as lock } from 'resources/icons/rotate-lock-fill.svg';
import { ReactComponent as SuccessIconModal } from 'resources/icons/SuccessIconModal.svg';
import { ReactComponent as ErrorIconModal } from 'resources/icons/ErrorIconModal.svg';
import { ReactComponent as Rule } from 'resources/icons/Rule.svg';
import { ReactComponent as Drivers } from 'resources/icons/Group 70.svg';
import { ReactComponent as HalfMoon } from 'resources/icons/Group 2275.svg';
import { ReactComponent as PencilFill } from 'resources/icons/pencil-fill.svg';
import { ReactComponent as SingatureError } from 'resources/icons/Group 2377.svg';
import { ReactComponent as Users } from 'resources/icons/Group 438.svg';
import { ReactComponent as TimeAdd } from 'resources/icons/time-add.svg';
import { ReactComponent as Assignment } from 'resources/icons/ic_assignment.svg';
import { ReactComponent as Add } from 'resources/icons/add.svg';
import { ReactComponent as Codriver } from 'resources/icons/people-fill.svg';
import { ReactComponent as OffDuty } from 'resources/icons/Group 2233.svg';
import { ReactComponent as SleeperBirth } from 'resources/icons/sleeperBirth.svg';
import { ReactComponent as Driving } from 'resources/icons/driving.svg';
import { ReactComponent as Vehicle } from 'resources/icons/Group 73.svg';
import { ReactComponent as Vehicle0 } from 'resources/icons/Group 001.svg';
import { ReactComponent as Error404 } from 'resources/icons/404Error.svg';
import { ReactComponent as Error500 } from 'resources/icons/500Error.svg';
import { ReactComponent as Exclamation } from 'resources/icons/exclamation-circle-fill.svg';
import { ReactComponent as Clock } from 'resources/icons/Group 2274.svg';
import { ReactComponent as CommentAlt } from 'resources/icons/comment-alt.svg';
import { ReactComponent as NoData } from 'resources/icons/Group 2380.svg';
import { ReactComponent as Download } from 'resources/icons/file_download.svg';
import { ReactComponent as EditHistory } from 'resources/icons/clock-history.svg';
import { ReactComponent as AddUser } from 'resources/icons/person-plus-fill.svg';

export const svgIcons = {
  AssetTypeIcon: AssetType,
  UserRoleIcon: UserRole,
  LengthIcon: Length,
  AxleIcon: Axle,
  GWRIcon: GWR,
  LocationIcon: Location,
  CalendarIcon: Calendar,
  GasPumpIcon: GasPump,
  eyeFillIcon: eyeFill,
  errorIcon: error,
  ReturnArrowIcon: ReturnArrow,
  EditIcon: Edit,
  DeleteIcon: Delete,
  IC_CalendarIcon: IC_Calendar,
  ELDIcon: ELD,
  SearchIcon: Search,
  SerialNumberIcon: SerialNumber,
  BuildingIcon: Building,
  RotateLockIcon: lock,
  SuccessModalIcon: SuccessIconModal,
  ErrorModalIcon: ErrorIconModal,
  RuleIcon: Rule,
  DriverIcon: Drivers,
  HalfMoonIcon: HalfMoon,
  PencilFillIcon: PencilFill,
  SingatureErrorIcon: SingatureError,
  UsersIcon: Users,
  TimeAddIcon: TimeAdd,
  AssignmentIcon: Assignment,
  AddRemarksIcon: Add,
  CodriverIcon: Codriver,
  OffDutyIcon: OffDuty,
  SleeperBirthIcon: SleeperBirth,
  DrivingIcon: Driving,
  VehicleIcon: Vehicle,
  Vehicle01: Vehicle0,
  Error404Icon: Error404,
  Error500Icon: Error500,
  ExclamationIcon: Exclamation,
  ClockIcon: Clock,
  CommentAltIcon: CommentAlt,
  NoDataIcon: NoData,
  DownloadIcon: Download,
  EditHistoryIcon: EditHistory,
  AddUserIcon: AddUser,
};

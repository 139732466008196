import LogoImage from './Group_34.png';
import UserProfileImage from './Mask Group 2.svg';
import Signature from './signature.png';
import DefectImg from './defectSample.png';

export const IMAGES = {
  BRAND_LOGO: LogoImage,
  USERPIC: UserProfileImage,
  DriverSignature: Signature,
  DefectImgSample: DefectImg,
};

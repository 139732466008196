import { useMutation } from 'react-query';
import authApi from '../services/auth';

export const useLoginMutation = () => {
  const response = useMutation(authApi.login, { mutationKey: 'login' });
  return response;
};

export const useForgotPassword = () => useMutation(authApi.forgotPassword, { mutationKey: 'forgotPassword' });

export const useResetPassword = () => useMutation(authApi.resetPassword, { mutationKey: 'resetPassword' });

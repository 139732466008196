export const DRIVER_API_BASE_URL = process.env.REACT_APP_DRIVER_API_BASE_URL || '';
export const VEHICLE_API_BASE_URL = process.env.REACT_APP_VEHICLE_API_BASE_URL || '';
export const AUTH_API_BASE_URL = process.env.REACT_APP_AUTH_API_BASE_URL || '';
export const USER_API_BASE_URL = process.env.REACT_APP_USER_API_BASE_URL || '';
export const COMPANY_API_BASE_URL = process.env.REACT_APP_COMPANY_API_BASE_URL || '';
export const ELD_API_BASE_URL = process.env.REACT_APP_ELD_API_BASE_URL || '';
export const ASSETS_API_BASE_URL = process.env.REACT_APP_ASSETS_API_BASE_URL || '';
export const OFFICES_API_BASE_URL = process.env.REACT_APP_OFFICES_API_BASE_URL || '';
export const ROLES_API_BASE_URL = process.env.REACT_APP_ROLES_API_BASE_URL || '';
export const PERMISSIONS_API_BASE_URL = process.env.REACT_APP_PERMISSIONS_API_BASE_URL || '';
export const HOS_API_BASE_URL = process.env.REACT_APP_HOS_API_BASE_URL || '';
export const UNITS_API_BASE_URL = process.env.REACT_APP_UNITS_API_BASE_URL || '';
export const INSPECTION_API_BASE_URL = process.env.REACT_APP_INSPECTION_API_BASE_URL || '';
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || '';
export const SUBMISSIONS_API_BASE_URL = process.env.REACT_APP_SUBMISSIONS_API_BASE_URL || '';

export const TENANT_ID = process.env.REACT_APP_TENANT_ID || '';

export const REQUEST_TIMEOUT = 50 * 1000;
export const SESSION_EXPIRED_STATUS_CODE = 401;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_CURRENT_PAGE = 1;
export const TOAST_MESSAGE_OPTIONS = {
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const ONE_DAY = 1000 * 84600;
// 10 Hours of Break Time
export const BREAK_TIME = 8 * 60 * 60;
// 1۱ Hours of Driving Time
export const DRIVING_TIME = 11 * 60 * 60;
// 14 Hours of Shift Time
export const SHIFT_TIME = 14 * 60 * 60;
// 24 Hours of Cycle Time
export const CYCLE_TIME = 70 * 60 * 60;
export const FORM_SIZE = 'small';
export const FORM_LAYOUT = 'vertical';
export const TABS_ALIGN_LEFT = 'true';
export const TABS_SIZE = 'large';
export const TABS_TYPE = 'card';
export const ROW_GUTTER = [16, 8];
export const USE_QUERY_OPTIONS = {
  retryDelay: 1000 * 3,
  retry: 0,
  refetchOnWindowFocus: false,
};
export const LIVE_REFRESH_INTERVAL = 20000;
export const ACTION_TYPE = {
  ON_DUTY_NOT_DRIVING: 'ON_DUTY_NOT_DRIVING',
  DRIVING: 'DRIVING',
  SLEEPER_BERTH: 'SLEEPER_BERTH',
  OFF_DUTY: 'OFF_DUTY',
  BREAK: 'BREAK',
};
export const STATUSES_ACTION_TYPE = {
  ON_DUTY_NOT_DRIVING: 'onDuty',
  DRIVING: 'onDriving',
  SLEEPER_BERTH: 'onSleeperBirth',
  OFF_DUTY: 'offDuty',
  BREAK: 'onBreak',
};

export const STATUSES_TYPE = {
  onDuty: 'ON_DUTY_NOT_DRIVING',
  onDriving: 'DRIVING',
  onSleeperBerth: 'SLEEPER_BERTH',
  offDuty: 'OFF_DUTY',
  onBreak: 'BREAK',
};
export const TRUCK_ICON_SVG_OPTIONS = {
  path: 'M73.3,83h16v-7h-0.2V39.2h12.6c-0.5-3-3.1-5.3-6.2-5.3h-5.4c-0.3,0-0.7,0-1,0.1V12.2c0-1.6-0.4-3.1-0.9-4.4 C89,7.3,89.6,5.7,89.6,4c0-2.1-0.8-4-1.9-4H15c-1.1,0-1.9,1.9-1.9,4c0,1.9,0.7,3.5,1.5,3.9c-0.6,1.3-1,2.9-1,4.6v21.8 c-0.6-0.2-1.3-0.3-2-0.3H6.2c-3.1,0-5.7,2.3-6.2,5.3h13.6V76h-0.3v7h16v5H5.8v137h91V88H73.3V83z M84.8,73H77l-0.4-10.8l8.2-22.5 C84.8,39.8,84.8,73,84.8,73z M35.8,14h32v3h-32V14z M35.8,18h32v3h-32V18z M35.8,22h32v3h-32V22z M25.8,73h-8V39.8l8.3,22.5 L25.8,73z M29.5,60l-9.4-26.8c5.9-3,24-4.5,30.7-4.5s24.8,1.5,30.7,4.5L72.2,60H29.5z',
  scale: 0.13,
  strokeColor: 'white',
  strokeWeight: 0.1,
  fillOpacity: 1,
  fillColor: '#404040',
  // offset: '5%',
};
export const HISTORY_MAP_INTERVAL = 50;
export const HISTORY_MAP_ZOOM = 10;
export const DEFAULT_MAP_ZOOM = 5;
export const UNITS_DASHBOARD_MAP_ZOOM = 5;
export const LIVE_MAP_ZOOM = 12;
export const PROFILE_MAP_ZOOM = 15;

export const FORBIDDEN_ERROR = 'This resource is not available to you';

import React from 'react';
import localStorageService from '../helpers/localStorageService';

import './PublicSection.less';

function PublicSection({
  children,
}: // eslint-disable-next-line @typescript-eslint/ban-types
React.PropsWithChildren<{}>): JSX.Element | null {
  const adminStatus = localStorageService.get('adminStatus');

  if (adminStatus === 'active') {
    return null;
  }

  return <div className="public-secion">{children}</div>;
}

export default PublicSection;

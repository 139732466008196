import { Layout, Row, Col, Dropdown, Menu, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { CaretDownOutlined } from '@ant-design/icons';

import { IMAGES } from 'resources/images/index';
import { ReactComponent as ToogleIcon } from 'resources/images/Group 35.svg';
import { ReactComponent as MoonIcon } from 'resources/images/Group 41.svg';
import { ReactComponent as CompanyIcon } from 'resources/images/Group 457.svg';
import { ReactComponent as NotificationIcon } from 'resources/images/Group 458.svg';
import { ReactComponent as EditProfileIcon } from 'resources/icons/Group 434.svg';
import { ReactComponent as ChangePasswordIcon } from 'resources/icons/Group 436.svg';
import { ReactComponent as UsersIcon } from 'resources/icons/Group 438.svg';
import { ReactComponent as ApiTokenIcon } from 'resources/icons/Group 441.svg';
import { ReactComponent as SubscriptionIcon } from 'resources/icons/Group 443.svg';
import { ReactComponent as ReceiptsIcon } from 'resources/icons/Group 444.svg';
import { ReactComponent as MonthlyStatementIcon } from 'resources/icons/Group 445.svg';
import { ReactComponent as FeedbackIcon } from 'resources/icons/Group 447.svg';
import { ReactComponent as LogoutIcon } from 'resources/icons/Group 448.svg';

import './Header.less';

const { Header } = Layout;

const HeaderBar = ({ showSidebar }: { showSidebar(): void }) => {
  const logout = () => {
    // clear all localStorage
    localStorage.clear();
    window.location.href = '/auth/login';
  };

  const getUserInfo = localStorage.getItem('userInfo');
  const loginUserData = JSON.parse(getUserInfo || '');

  return (
    <>
      <Layout>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <header className="navbar">
            <div className="navbar__title navbar__item">
              <ToogleIcon className="toggle-navbar cursor-pointer" onClick={showSidebar} />
            </div>
            <Row className="toprow">
              <Col className="inlineflex" span={12}>
                <div className="company-logo">
                  <Link to="/dashboard">
                    <img src={IMAGES.BRAND_LOGO} alt="LOG ELD" />
                  </Link>
                </div>
              </Col>
              <Col className="inlineflex navMenu" span={12}>
                {/* <div className="navbar__item navbar_icons">
                  <MoonIcon />
                </div> */}
                <Link to="/company">
                  <Popover title="Company">
                    <div className="navbar__item navbar_icons">
                      <CompanyIcon />
                    </div>
                  </Popover>
                </Link>
                {/* <div className="navbar__item navbar_icons">
                  <NotificationIcon />
                </div> */}
                <div className="navbar__item navbar_icons">
                  <div className="vr_line" />
                </div>
                <div className="navbar__item">
                  <Dropdown
                    placement="bottom"
                    overlay={
                      <Menu key="top-menu" className="menu">
                        <div className="welcomeWrapper">
                          <Menu.Item key="welcome-menuitem">
                            <div className="arrow-up " />
                            <span className="profile-dropdown-heading"> Welcome {loginUserData.firstName}</span>
                            <div className="dropDownhrline" />
                          </Menu.Item>
                        </div>
                        {/* <Menu.Item key="edit-profile-menuitem">
                          <div className="Icons_List profile-dropdown-items">
                            <EditProfileIcon />
                            <span className="dropDown-text">Edit Profile</span>
                          </div>
                        </Menu.Item> */}
                        {/* <Menu.Item key="change-password-menuitem">
                          <div className="Icons_List">
                            <ChangePasswordIcon />
                            <span className="dropDown-text">Change Password</span>
                          </div>
                        </Menu.Item> */}
                        <Menu.Item key="users-menuitem">
                          <Link to="/users">
                            <UsersIcon />
                            <span className="dropDown-text">Users</span>
                          </Link>
                        </Menu.Item>
                        {/* <Menu.Item key="api-token-menuitem">
                          <ApiTokenIcon />
                          <span className="dropDown-text">API Tokens</span>
                        </Menu.Item>
                        <Menu.Item key="subscription-menuitem">
                          <SubscriptionIcon />
                          <span className="dropDown-text">Subscription</span>
                        </Menu.Item>
                        <Menu.Item key="receipts-menuitem">
                          <ReceiptsIcon />
                          <span className="dropDown-text">Receipts</span>
                        </Menu.Item>
                        <Menu.Item key="monthly-statement-menuitem">
                          <MonthlyStatementIcon />
                          <span className="dropDown-text">Monthly Statements</span>
                        </Menu.Item>
                        <Menu.Item key="feedback-menuitem">
                          <FeedbackIcon />
                          <span className="dropDown-text">Feedback</span>
                        </Menu.Item> */}
                        {/* <div className="dropDownhrlineBottom" /> */}
                        <Menu.Item key="logout-menuitem" onClick={() => logout()}>
                          <LogoutIcon />
                          <span className="dropDown-text"> Logout</span>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}>
                    <a href="/#" onClick={e => e.preventDefault()}>
                      <div className="profile_CompleteName">
                        <img className="img-fluid" src={IMAGES.USERPIC} alt="profile-img" />
                        <div className="NameAndFoundation">
                          <p className="completeName">{`${loginUserData?.firstName || ''}  ${
                            loginUserData?.lastName || ''
                          }`}</p>
                          <span className="designation">{`${loginUserData?.role?.roleName || ''}`}</span>
                        </div>
                        <CaretDownOutlined className="DownOutIcon" />
                      </div>
                    </a>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </header>
        </Header>
      </Layout>
    </>
  );
};
export default HeaderBar;

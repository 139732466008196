// Login Mutations
export const LOGIN_MUTATION = 'loginMutation';

// User
export const GET_USERS = 'getUsers';

// Driver
export const GET_DRIVER = 'getDrivers';

// Vehicle
export const GET_VEHICLE = 'getVehicles';

// ELDs
export const GET_ELDS = 'getElds';

// Assets
export const GET_ASSETS = 'getAssets';

// Comapny
export const GET_COMPANY = 'getCompany';

// Offices
export const GET_OFFICES = 'getOffices';

// Roles
export const GET_ROLES = 'getRoles';

// Permissions
export const GET_PERMISSIONS = 'getPermissions';

// HOS History Data
export const GET_HOS = 'getHistroy';

// HOS Live Data
export const GET_HOS_LIVE = 'getHOSLive';

// HOS Profile Data
export const GET_HOS_PROFILE = 'getHOSProfile';

// Driver History Logs
export const GET_HOS_DRIVER_LOGS = 'getDriverHistoryLog';

// Driver Graph Data
export const GET_HOS_DRIVER_GRAPH_DATA = 'getDriverGraphData';

// Units
export const GET_UNITS = 'getUnits';

// HOS Clock
export const GET_HOS_CLOCK = 'getClock';

// Log Form
export const GET_LOG_FORM = 'getLogForm';

// Inspection

export const GET_INSPECTION = 'getInspection';

export const GET_INSPECTION_REPORT = 'getInspectionReport';

// Cache
export const GET_CACHE_DATA = 'getCacheData';

import * as yup from 'yup';
import { emailRegEx } from 'constants/regex';

export const LoginValidation = yup.object().shape({
  userId: yup.string().nullable(true).required('User ID is required'),
  password: yup.string().nullable(true).required('Password is required'),
});

export const UpdateProfileValidation = yup.object().shape({
  userName: yup.string().nullable(true).required('User name is required'),
  emailId: yup.string().nullable(true).email('Email id is invalid').required('Email id is required'),
});

export const ForgotPasswordValidation = yup.object().shape({
  email: yup.string().nullable(false).required('Email is required').matches(emailRegEx, 'Email is not valid'),
});

export const ResetPasswordValidation = yup.object().shape({
  password: yup.string().nullable(true).required('Password is required').min(8, 'Must be at least 8 characters.'),
  confirmPassword: yup
    .string()
    .nullable(true)
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Your password do not match.'),
});

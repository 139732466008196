import axios from 'axios';

import { AUTH_API_BASE_URL, REQUEST_TIMEOUT } from 'constants/config';
import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginError,
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from 'types/auth';
import httpRequest from './config/HttpRequest';

const request = axios.create({
  baseURL: AUTH_API_BASE_URL,
  timeout: REQUEST_TIMEOUT,
});

const authApi = {
  login: (body: LoginRequest): Promise<LoginResponse> =>
    new Promise((resolve, reject) => {
      request
        .post('/auth/login', body)
        .then(res => {
          resolve(res.data);
        })
        .catch((error: LoginError) => {
          reject(error?.response?.data?.error);
        });
    }),

  forgotPassword: async (body: ForgotPasswordRequest): Promise<ForgotPasswordResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: AUTH_API_BASE_URL,
        url: '/auth/forgot_password',
        method: 'POST',
        data: body,
      });
      return response;
    } catch (error) {
      // console.log("error", error);
      // console.log("typeOf error", typeof(error));
      throw new Error(error as string);
    }
  },

  resetPassword: async (body: ResetPasswordRequest): Promise<ResetPasswordResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: AUTH_API_BASE_URL,
        url: '/auth/reset_password',
        method: 'PATCH',
        data: body,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },
};

export default authApi;

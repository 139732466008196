import localStorageService from 'helpers/localStorageService';
import axiosRequest from './axios';

export const getHeader = <T>(header: T) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: '',
  };
  const token = localStorageService.get('jwt');
  if (token) defaultHeaders.Authorization = `Bearer ${token}`;
  if (header) return { ...defaultHeaders, ...header };

  return defaultHeaders;
};

type Request<P, D, H> = {
  baseURL?: string;
  method: 'POST' | 'PUT' | 'GET' | 'PATCH' | 'DELETE';
  url: string;
  params?: P;
  data?: D;
  header?: H;
};

const request = <P, D, H>({ baseURL, method, url, params, data, header }: Request<P, D, H>) => {
  console.groupCollapsed(baseURL);
  console.log({ method });
  console.log({ url });
  console.log({ params });
  console.log({ data });
  console.log({ header });
  console.groupEnd();

  // eslint-disable-next-line

  return axiosRequest({
    baseURL,
    method,
    url,
    params,
    data,
    headers: getHeader(header),
  });
};

const httpRequest = {
  request,
};

export default httpRequest;

import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import history from 'helpers/history';
import AuthContainer from 'modules/auth/Auth.container';
import Loader from 'components/loader';
import Error404 from 'components/ServerErrors/404Error/Error404';
import Error500 from 'components/ServerErrors/500Error/Error500';

import Landing from './Landing';
import PrivateSection from './PrivateSection';
import PublicSection from './PublicSection';

const DashboardContainer = lazy(() => import('../modules/dashboard/Dashboard.container'));
const UserContainer = lazy(() => import('../modules/user/User.container'));
const DriverContainer = lazy(() => import('../modules/driver/Driver.container'));
const VehicleContainer = lazy(() => import('../modules/vehicle/Vehicle.container'));
const EldContainer = lazy(() => import('../modules/eld/Eld.container'));
const AssetContainer = lazy(() => import('../modules/asset/Asset.container'));
const UnitContainer = lazy(() => import('modules/units/Units.container'));
const OfficeContainer = lazy(() => import('../modules/office/Office.container'));
const LogsContainer = lazy(() => import('../modules/logs/Logs.container'));
const SubmissionsContainer = lazy(() => import('../modules/submissions/Submissions.container'));
const ErrorContainer = lazy(() => import('../components/ServerErrors/404Error/Error404'));

export default function AppRoute(): JSX.Element {
  return (
    <Router history={history}>
      <PublicSection>
        <Switch>
          <Redirect path="/" exact to="/auth" />
          <Route path="/auth">
            <AuthContainer />
          </Route>

          <Redirect path="*" exact to="/landing" />
        </Switch>
      </PublicSection>
      <PrivateSection>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Redirect path="/" exact to="/landing" />
            <Route path="/landing">
              <Landing />
            </Route>
            <Route path="/dashboard">
              <DashboardContainer />
            </Route>
            <Route path="/users">
              <UserContainer />
            </Route>
            <Route path="/company">
              <OfficeContainer />
            </Route>
            <Route path="/drivers">
              <DriverContainer />
            </Route>
            <Route path="/vehicles">
              <VehicleContainer />
            </Route>
            <Route path="/elds">
              <EldContainer />
            </Route>
            <Route path="/asset">
              <AssetContainer />
            </Route>
            <Route path="/units">
              <UnitContainer />
            </Route>
            <Route path="/logs">
              <LogsContainer />
            </Route>
            <Route path="/submissions">
              <SubmissionsContainer />
            </Route>
            <Route path="/PageNotFound">
              <ErrorContainer />
            </Route>
            <Route path="/Error500">
              <Error500 />
            </Route>
            <Route path="*" component={ErrorContainer} />
          </Switch>
        </Suspense>
      </PrivateSection>
    </Router>
  );
}

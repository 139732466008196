import { Link, useHistory } from 'react-router-dom';
import { svgIcons } from 'resources/icons';

import '../404Error/Error404.less';

const Error500 = () => {
  const history = useHistory();

  return (
    <div className="Wrapper">
      <div className="ServerErrorContainer">
        <div>
          <svgIcons.Error500Icon />
        </div>
      </div>
      <div className="ErrorMessage">
        <p className="errorHeading">500 - Internal Server Error</p>
        <p className="errorText">We are working towards creating something better.</p>
      </div>
      <div className="Routes">
        <span className="pathRoute-text">
          Go to the
          <Link to="/">
            <span className="pathLink">dashboard</span>
          </Link>
        </span>
      </div>
    </div>
  );
};
export default Error500;
